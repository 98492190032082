.currency-input:focus-visible {
    outline:0.4px solid transparent!important;
}
.currency-input {
    border:transparent;
    background: transparent!important;
    padding: 5px 0 5px 4px!important;
    margin: 5px;
    height:45px;
    width:100%;
    font-size:16px;
}